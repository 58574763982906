import styled from "@emotion/styled";
import React from "react";

import Button from "../components/Button";
import Heading from "../components/Heading";
import Link from "../components/Link";
import SEO from "../components/SEO";
import Text from "../components/Text";

const UnstyledAnchor = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const PageWrapper = styled.div`
  margin: 100px auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  max-width: 600px;
`;

const Title = styled(Heading)`
  max-width: 540px;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
`;

const ButtonWrapper = styled.div`
  position: relative;
  margin-top: 30px;
`;

const facts = [
  "Dassen zijn de snelst gravende dieren op aarde!",
  "Mannetjes dassen noemen wij beren en vrouwtjes zeugen.",
  "Mannetjes (beren) en vrouwtjes (zeugen) zijn moeilijk van elkaar te onderscheiden.",
  "Voor een das bestaat de wereld uit geuren, het reukvermogen is zeer goed. Ze ruiken maar liefst 700 tot 800 keer meer dan mensen.",
  "Dassen kunnen vrij stil zijn, maar maken ook geregeld geluid om met elkaar te communiceren. Ze grommen, piepen, huilen, blaffen, knorren en gillen.",
  "Mannetjes dassen zijn erg verdedigend en sterke vechtersbazen.",
  "Dassen paren het hele jaar, maar vooral vroeg in het voorjaar. In het najaar van haar eerste levensjaar kan het zeugje al drachtig worden.",
  "Jonge dassen krijgen tanden als ze ongeveer een maand oud zijn.",
  "Dassen zijn robuuste dieren die niet snel ziek worden.",
  "Lang geleden werd de das gezien als een beschermdier. In de Romeinse tijd geloofde men dat amuletten van dassenpoten voor veiligheid zorgden en een dassenvel bij paarden zou tovenarij afweren.",
  "In 1942 werd de das in Nederland beschermd verklaard, met de mogelijkheid een afschotvergunning aan te vragen bij landbouwschade.",
  "Tegenwoordig zijn dassen in Nederland een van de grootste bedreigingen in het verkeer. Dit heeft veel slachtoffers tot gevolg, met een piek in februari en maart.",
  "In 1980 was de dassenpopulatie met ongeveer 1.200 dieren in Nederland zeer laag. Sinds 1990 herstelt de populatie zich.",
  "Dassen zijn echte alleseters, ze eten al het plantaardig en dierlijk voedsel dat ze kunnen bemachtigen",
];
const getRandomFact = (_facts = []) =>
  _facts[Math.round(Math.random() * (_facts.length - 1))];

const NotFoundPage = () => (
  <>
    <SEO
      tags={[
        {
          tag: "title",
          content: "404 | Greenberry",
        },
      ]}
    />
    <PageWrapper>
      <ImageWrapper>
        <img src="/404.svg" alt="not found" />
      </ImageWrapper>

      <Title size={2} color="secondary">
        Das jammer...
        <br />
        We hebben flink gegraven, maar kunnen deze pagina niet vinden!
      </Title>
      <Text>
        Random fact:
        <br />
        {getRandomFact(facts)}
      </Text>
      <ButtonWrapper>
        <UnstyledAnchor to={"/"}>
          <Button styling="button" as="span">
            <span>Terug naar home</span>
          </Button>
        </UnstyledAnchor>
      </ButtonWrapper>
    </PageWrapper>
  </>
);

export default NotFoundPage;
